import PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo/seo'
import StarredLink from '../components/starred-link/starred-link'
import HeroImg from '../assets/images/hero-landing-create.png'

const query = graphql`
    query {
        contentfulMainPage {
            servicesTitle
            servicesSubtitle
            servicesItems {
                id
                title
                description
                shortDescription
                link
            }
        }
    }
`

const Content = ({ location }) => {
    const currentLocation =
        typeof window !== 'undefined' ? window.location.pathname : ''
    const currentLink = currentLocation.replace(/^\/+|\/+$/g, '')
    const data = useStaticQuery(query)
    const otherServices = data.contentfulMainPage.servicesItems.filter(item => {
        return item.link !== currentLink
    })
    const pageTitle = 'Create a new brand or website'

    return (
        <Layout>
            <Seo
                title={`${pageTitle} | South Lane`}
                desc="Whether you have an existing brand or want to create one from scratch, we can help shape how your customers perceive you in a way that results in engagement, loyalty, and success."
                pathname={location.pathname}
            />
            <div className="content-narrow">
                <div className="heading-container heading-container--how-we-can-help">
                    <p>How we can help</p>
                    <h2>{pageTitle}</h2>
                    <div className="tags-list">
                        <div className="tags-list__item">
                            <div className="tag-item">Brand Strategy</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Brand Identity</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Brand Guidelines</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Content</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Websites</div>
                        </div>
                    </div>

                    <div className="common-content-block">
                        <p>
                            Your brand is more than just your logo. It&apos;s
                            how your customers feel about you. It&apos;s about
                            the emotions evoked when they come into contact with
                            your company or product. It could be how you
                            communicate with them. It could be the interactions
                            they have with your product. It could be how they
                            feel after speaking with your customer service team.
                        </p>
                        <p>
                            But it&apos;s also not all about them. It&apos;s
                            about you too. Branding is about telling your story.
                            Why you are here and how you are unique. How you
                            have helped customers just like them. Branding is
                            about being honest, human, creating connections, and
                            building relationships.
                        </p>
                    </div>
                </div>
            </div>

            <div className="content-hero-image content-hero-image--landing">
                <img src={HeroImg} alt="Create a new brand or website" />
            </div>

            <div className="content-narrow">
                <div className="content-blocks-list content-blocks-list--services-landing">
                    <div className="common-content-block">
                        <p>
                            We design <strong>logos</strong> and{' '}
                            <strong>wordmarks</strong> that are unique, ownable,
                            and flexible. We define{' '}
                            <strong>color palettes</strong> and{' '}
                            <strong>typography</strong> to enhance your brand
                            character. And customize <strong>icons</strong>,{' '}
                            <strong>illustrations</strong>, and{' '}
                            <strong>images</strong> that bring your story and
                            product to life.
                        </p>
                        <p>
                            We make sure what you say and how you say it, lines
                            up with your <strong>brand strategy</strong> through{' '}
                            <strong>tone of voice</strong> and{' '}
                            <strong>messaging</strong>. We create{' '}
                            <strong>content</strong> that is engaging and adds
                            value. And build <strong>websites</strong> that
                            achieve both business and user goals.
                        </p>
                        <p>
                            Whether you have an existing brand or want to create
                            one from scratch, we can help shape how your
                            customers perceive you in a way that results in
                            engagement, loyalty, and success. We establish clear
                            messaging that makes sure you are effectively
                            communicating how you satisfy their hopes, needs,
                            and desires. And characterize the way you interact
                            with them so that you are seen and heard in a way
                            they can relate to.
                        </p>
                    </div>
                </div>

                <div className="content-blocks-list content-blocks-list--services-landing-2">
                    <div className="common-content-block">
                        <h6>More ways we can help</h6>
                    </div>
                </div>

                {otherServices.map(item => (
                    <p key={item.link} className="no-font-size">
                        <StarredLink text={item.title} link={item.link} />
                    </p>
                ))}
            </div>
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
